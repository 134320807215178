import NFTItem from "../NFTItem/NFTItem";
import React from "react";
import {NFTResponse} from "../../interfaces/nft-response";
import './CollectionGrid.css';

interface Props {
    showModal: () => void;
    updateList: () => void;
    nfts: NFTResponse[],
    inline: boolean
}

const CollectionGrid = ({showModal, updateList, nfts, inline}: Props) => {
    return (
        <div className={`grid ${inline ? 'inline' : ''}`}>
            {nfts.map(nft => (
                <NFTItem key={nft.identifier} nft={nft} showModal={showModal} updateList={updateList}/>
            ))}
        </div>
    )
}

export default CollectionGrid;