import React, {useEffect, useState} from 'react';
import {sendTransactions} from "@multiversx/sdk-dapp/services";
import {useGetIsLoggedIn} from "@multiversx/sdk-dapp/hooks";
import {useNavigate} from "react-router-dom";

// TODO: FUTURE
// TODO: transfer ownership of contracts to someone else
const Dashboard = () => {
    const [, setTransactionSessionId] = useState<string | null>(null);
    const contractAddress = String(process.env.REACT_APP_CONTRACT_ADDRESS)
    const navigate = useNavigate();
    const isLoggedIn = useGetIsLoggedIn();

    useEffect(() => {
        if(!isLoggedIn) {
            navigate("/");
        }
    }, [isLoggedIn, navigate]);

    const issueToken = async () => {
        try {
            const {sessionId, error} = await sendTransactions({
                transactions: [
                    {
                        value: '50000000000000000', // Value of the transaction (in wei)
                        data: 'issueToken' +
                            "@" + Buffer.from('MonsterLandrCrew').toString("hex") +
                            "@" + Buffer.from('MONSY').toString("hex"),
                        gasLimit: 60000000,
                        receiver: contractAddress, // Address of the receiver
                    },
                ],
                callbackRoute: '/dashboard', // Optional callback route
            });

            if (error) {
                console.error('Error sending transaction:', error);
            } else {
                console.log('Transaction sent successfully. Session ID:', sessionId);
                setTransactionSessionId(sessionId); // Store the session ID for tracking
            }
        } catch (error) {
            console.error('Error sending transaction:', error);
        }
    };

    const setLocalRoles = async () => {
        try {
            const {sessionId, error} = await sendTransactions({
                transactions: [
                    {
                        value: '0', // Value of the transaction (in wei)
                        data: 'setLocalRoles',
                        gasLimit: 60000000,
                        receiver: contractAddress, // Address of the receiver
                    },
                ],
                callbackRoute: '/dashboard', // Optional callback route
            });

            if (error) {
                console.error('Error sending transaction:', error);
            } else {
                console.log('Transaction sent successfully. Session ID:', sessionId);
                setTransactionSessionId(sessionId); // Store the session ID for tracking
            }
        } catch (error) {
            console.error('Error sending transaction:', error);
        }
    };


    return (
        <div>
            <h1>Welcome to My Dashboard</h1>

            <button className="button" onClick={issueToken}>Issue Token</button>
            <button className="button" onClick={setLocalRoles}>Set local roles</button>
        </div>
    );
};

export default Dashboard;
