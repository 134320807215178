import React, {useEffect, useState} from 'react';
import {useGetIsLoggedIn} from "@multiversx/sdk-dapp/hooks";
import {Link, useNavigate} from "react-router-dom";
import Icon from "../../components/Icon";
import styles from "./MultiUpload.module.sass";
import Control from '../../components/Control';
import UploadFiles from '../../components/UploadFiles/UploadFiles';
import {SingleNft, useTransactionService} from "../../services/transaction.service";
import TextInput from "../../components/TextInput";
import {Fields} from "./Fields";

const breadcrumbs = [
    {
        title: "Home",
        url: "/",
    },
    {
        title: "Multi Upload",
    },
];

// TODO: FUTURE users can pass a regex on how the file name will be converted to the nft token name
const MultiUpload = () => {
    //form
    const [ipfsUrls, setIpfsUrls] = useState<SingleNft[]>([]);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [royalties, setRoyalties] = useState('');

    const navigate = useNavigate();
    const isLoggedIn = useGetIsLoggedIn();
    const contractAddress = String(process.env.REACT_APP_CONTRACT_ADDRESS);
    const {createMultipleNfts} = useTransactionService(contractAddress);

    // Function to handle IPFS URL received from UploadFiles component
    const handleIpfsUrl = (url: string, name: string) => {
        setIpfsUrls(prevUrls => [...prevUrls, {url, name, price: 0}]);
        setIsButtonDisabled(false)
    };

    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/");
        }
    }, [isLoggedIn, navigate]);

    const handleTransaction = async () => {
        await createMultipleNfts(
            ipfsUrls,
            Number(royalties.replace(/%/g, "")),
        );
    };

    const handleFieldChange = (index: number, field: string, value: string) => {
        setIpfsUrls(prevUrls => {
            const updatedUrls = [...prevUrls];
            // @ts-ignore
            updatedUrls[index][field] = value;
            return updatedUrls;
        });
        console.log(ipfsUrls)
        setIsButtonDisabled(false)
    };

    const handleDeleteItem = (index: number) => {
        setIpfsUrls(prevUrls => {
            const updatedUrls = [...prevUrls];
            updatedUrls.splice(index, 1);
            return updatedUrls;
        });

        if (ipfsUrls.length === 0) {
            setIsButtonDisabled(true)
        } else {
            setIsButtonDisabled(false)
        }
    };

    const handleDefaultPrice = (defaultPrice: string) => {
        const price = Number(defaultPrice)
        // Iterate over each item in ipfsUrls and set the default price
        const updatedUrls = ipfsUrls.map(item => ({ ...item, price }));
        setIpfsUrls(updatedUrls);
        setIsButtonDisabled(false)
    };

    return (
        <>
            <Control className={styles.control} item={breadcrumbs}/>
            <div className={`section ${styles.section}`}>
                <div className={`container ${styles.container}`}>
                    <div className={styles.form}>
                        <div className={styles.head}>
                            <div className={`h2 ${styles.title}`}>
                                <div>Create multiple NFTs</div>

                                <Link
                                    className={`button-stroke button-small ${styles.button}`}
                                    to="/upload"
                                >
                                    <span>Switch to Single Upload</span>
                                </Link>
                            </div>
                        </div>
                        <form className={styles.form} action="">
                            <div className={styles.list}>
                                <div className={styles.item}>
                                    <div className={styles.category}>Upload files</div>
                                    <div className={styles.note}>
                                        Drag or choose your files to upload
                                    </div>
                                    <UploadFiles ipfsUrl={handleIpfsUrl}/>
                                </div>

                                {ipfsUrls.length > 0 ?
                                    <>
                                        <ul className={styles.uploaded}>
                                            {ipfsUrls.map((item: SingleNft, index) => (
                                                <li key={index}>
                                                    <div>
                                                        <img src={item.url} alt=""/>
                                                    </div>
                                                    <div className={styles.fields}>
                                                        <TextInput
                                                            onInputChange={(e: any) => handleFieldChange(index, 'name', e.target.value)}
                                                            className={styles.field}
                                                            value={item.name}
                                                            name="Price"
                                                            type="text"
                                                            placeholder="Item Name e. g. Bord Ape"
                                                            required/>
                                                        <TextInput
                                                            onInputChange={(e: any) => handleFieldChange(index, 'price', e.target.value)}
                                                            className={styles.field}
                                                            addon="EGLD"
                                                            value={item.price === 0 ? '' : item.price}
                                                            name="Price"
                                                            type="text"
                                                            placeholder="Item Price e. g. 2.5"
                                                            required/>
                                                    </div>
                                                    <div className={styles.delete}>
                                                        <svg
                                                            onClick={() => handleDeleteItem(index)}
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M4.99434 3.90637C4.73844 3.90637 4.47084 3.99238 4.27554 4.18738C3.88504 4.57838 3.88504 5.23437 4.27554 5.62537L10.5568 11.9064L4.27554 18.1874C3.88504 18.5784 3.88504 19.2344 4.27554 19.6254C4.66614 20.0154 5.32254 20.0154 5.71314 19.6254L11.9943 13.3444L18.2755 19.6254C18.6661 20.0154 19.3225 20.0154 19.7131 19.6254C20.1036 19.2344 20.1036 18.5784 19.7131 18.1874L13.4318 11.9064L19.7131 5.62537C20.1036 5.23437 20.1036 4.57838 19.7131 4.18738C19.5178 3.99238 19.2501 3.90637 18.9943 3.90637C18.7384 3.90637 18.4709 3.99238 18.2755 4.18738L11.9943 10.4684L5.71314 4.18738C5.51784 3.99238 5.25024 3.90637 4.99434 3.90637Z" fill="black"/>
                                                        </svg>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                        <Fields onRoyaltiesChange={setRoyalties} onDefaultPriceChange={handleDefaultPrice}/></>
                                    : ''}
                            </div>
                            <div className={styles.foot}>
                                <button
                                    className={`button ${styles.button} ${isButtonDisabled ? 'disabled' : ''}`}
                                    onClick={handleTransaction}
                                    type="button"
                                >
                                    <span>Create item</span>
                                    <Icon name="arrow-next" size="10"/>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MultiUpload;
