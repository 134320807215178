import React from "react";
import styles from "./TextInput.module.sass";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    className?: string;
    label?: string;
    onInputChange: (value: string) => void;
    addon?: string; // Optional add-on text
}

const TextInput = ({ className, label, onInputChange, addon, ...props }: Props) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (onInputChange) {
            onInputChange(value);
        }
    };

    return (
        <div className={`${styles.field} ${className}`}>
            {label && <div className={styles.label}>{label}</div>}
            <div className={styles.inputWrap}>
                <input className={styles.input} onChange={handleChange} {...props} />
                {addon && <div className={styles.addon}>{addon}</div>}
            </div>
        </div>
    );
};

export default TextInput;
