export const fetchNFTCount = async (
    contractAddress: string,
    collections: string,
    type: string
): Promise<number> => {
    const url = `${process.env.REACT_APP_API_URL}/api/accounts/${contractAddress}/nfts/count?collections=${collections}&type=${type}`
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching NFT count:', error);
        throw error;
    }
};