import { useState } from 'react';
import { sendTransactions } from "@multiversx/sdk-dapp/services";
import { ConvertArgument } from "../helpers/convert-argument";
import {useEthWeiConverter} from "../helpers/price-converter";

interface TransactionService {
    createNft: (tokenName: string, royalties: number, uri: string, sellingPrice: number, metadataUri?: string) => Promise<void>;
    createMultipleNfts: (nfts: SingleNft[], royalties: number) => Promise<void>;
    transactionSessionId: string | null;
    error: string | null;
}

export interface SingleNft {
    name: string;
    url: string;
    price: number;
    royalties?: number;
}

export const useTransactionService = (contractAddress: string): TransactionService => {
    const [transactionSessionId, setTransactionSessionId] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const ethWeiConverter = useEthWeiConverter();

    const createNft = async (
        tokenName: string,
        royalties: number,
        uri: string,
        sellingPrice: number,
        metadataUri?: string,
    ): Promise<void> => {
        royalties = royalties * 100

        try {
            sellingPrice = ethWeiConverter.ethToWei(sellingPrice)
            console.log(sellingPrice)
            const { sessionId, error } = await sendTransactions({
                transactions: [
                    {
                        value: '0',
                        data: 'createNft' +
                            "@" + Buffer.from(tokenName).toString("hex") +
                            "@" + ConvertArgument.numericValue(royalties) +
                            "@" + Buffer.from(uri).toString("hex") +
                            "@" + Buffer.from(String(metadataUri)).toString("hex") +
                            "@" + ConvertArgument.numericValue(sellingPrice),
                        gasLimit: 60000000,
                        receiver: contractAddress,
                    },
                ],
                callbackRoute: '/upload',
            });

            if (error) {
                // Handle error
                console.error('Error sending transaction:', error);
                setError(error);
            } else {
                // Transaction sent successfully
                console.log('Transaction sent successfully. Session ID:', sessionId);
                setTransactionSessionId(sessionId); // Store the session ID for tracking
            }
        } catch (error: any) {
            console.error('Error sending transaction:', error);
            setError(error.message || 'Unknown error occurred');
        }
    };

    const createMultipleNfts = async (
        nfts: SingleNft[],
        royalties: number,
    ): Promise<void> => {
        royalties = royalties * 100

        try {
            const transactions = nfts.map(nft => {
                const { name, url, price } = nft;
                const weiPrice = ethWeiConverter.ethToWei(price);
                console.log(weiPrice);
                const data =
                    'createNft' +
                    "@" + Buffer.from(name).toString("hex") +
                    "@" + ConvertArgument.numericValue(royalties) +
                    "@" + Buffer.from(url).toString("hex") +
                    "@" + Buffer.from(String('')).toString("hex") +
                    "@" + ConvertArgument.numericValue(weiPrice);

                return {
                    value: '0',
                    data,
                    gasLimit: 60000000,
                    receiver: contractAddress,
                };
            });
            const { sessionId, error } = await sendTransactions({
                transactions,
                callbackRoute: '/multi-upload',
            });

            if (error) {
                // Handle error
                console.error('Error sending transactions:', error);
                setError(error);
            } else {
                // Transaction sent successfully
                console.log('Transactions sent successfully. Session ID:', sessionId);
                setTransactionSessionId(sessionId); // Store the session ID for tracking
            }
        } catch (error: any) {
            console.error('Error sending transactions:', error);
            setError(error.message || 'Unknown error occurred');
        }
    };

    return {
        createNft,
        createMultipleNfts,
        transactionSessionId,
        error,
    };
};