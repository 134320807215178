interface EthWeiConverter {
    ethToWei: (ethAmount: number) => number;
    weiToEth: (weiAmount: string) => number;
}

export const useEthWeiConverter = (): EthWeiConverter => {
    const ethToWei = (ethAmount: number): number => {
        // @ts-ignore
        const weiPerEth = 10n ** 18n;
        return Number(Math.floor(ethAmount * Number(weiPerEth)));
    };

    const weiToEth = (weiAmount: string): number => {
        // @ts-ignore
        const weiPerEth = 10n ** 18n;
        return parseFloat(weiAmount) / parseFloat(weiPerEth.toString());
    };

    return {
        ethToWei,
        weiToEth,
    };
};

