import React, {useState} from 'react';
import Icon from '../Icon';
import styles from "./UploadFile.module.sass";
import useIpfsService from "../../services/ipfs.service";
import Loader from "../Loader";

interface Props {
    ipfsUrl: (url: string) => void
}

const UploadFile = ({ipfsUrl}: Props) => {
    const ipfsService = useIpfsService();
    const [isLoading, setIsLoading] = useState(false);

    const handleFileUpload = async (event: any) => {
        setIsLoading(true)
        const uploadedFile = event.target.files[0];

        if (!uploadedFile) return;

        try {
            const {url} = await ipfsService.uploadFile(uploadedFile);
            // Store IPFS URL
            ipfsUrl(url);
            console.log('Image uploaded to IPFS:', url);
        } catch (error) {
            console.error('Error uploading image to IPFS:', error);
        }
        setIsLoading(false)
    };

    return (
        <div className={styles.file}>
            {isLoading ? <Loader className={styles.loader}/> :
                <>
                    <input className={styles.load} type="file" onChange={handleFileUpload}/>
                    <div className={styles.icon}>
                        <Icon name="upload-file" size="24"/>
                    </div>
                    <div className={styles.format}>
                        PNG, GIF, WEBP, MP4 or MP3. Max 1Gb.
                    </div>
                </>}
        </div>
    );
};

export default UploadFile;
