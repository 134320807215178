import React from 'react';
import './App.css';
import {DappProvider} from "@multiversx/sdk-dapp/wrappers";
import {
    NotificationModal,
    SignTransactionsModals,
    TransactionsToastList,
} from "@multiversx/sdk-dapp/UI";
import routes from "./routes";
import {Route, BrowserRouter as Router, Routes} from "react-router-dom";

function App() {
    return (
        <DappProvider
            environment="devnet"
            dappConfig={{
                shouldUseWebViewProvider: true,
            }}
        >
            <Router>
                <TransactionsToastList className="transaction-list" />
                <NotificationModal/>
                <SignTransactionsModals/>
                <Routes>
                    {routes.map((route, index) => (
                        <Route
                            path={route.path}
                            key={"route-key-" + index}
                            element={React.createElement(route.component)}
                        />
                    ))}

                </Routes>
            </Router>
        </DappProvider>
    );
}

export default App;
