import React, {useEffect, useState} from 'react';
import {useGetIsLoggedIn} from "@multiversx/sdk-dapp/hooks";
import {Link, useNavigate} from "react-router-dom";
import Dropdown from "../../components/Dropdown";
import Icon from "../../components/Icon";
import TextInput from "../../components/TextInput";
import Preview from "./Preview";
import styles from "./Upload.module.sass";
import Control from '../../components/Control';
import UploadFile from '../../components/UploadFile/UploadFile';
import {useTransactionService} from "../../services/transaction.service";
import useIpfsService from "../../services/ipfs.service";

const royaltiesOptions = ["10%", "20%", "30%"];
const breadcrumbs = [
    {
        title: "Home",
        url: "/",
    },
    {
        title: "Upload Item",
    },
];

// TODO: make sure description can only contain text and numbers
const Upload = () => {
    const [royalties, setRoyalties] = useState(royaltiesOptions[0]);
    const [price, setPrice] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [imageUrl, setImageUrl] = useState<string>('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const [visiblePreview, setVisiblePreview] = useState(false);
    const navigate = useNavigate();
    const isLoggedIn = useGetIsLoggedIn();
    const contractAddress = String(process.env.REACT_APP_CONTRACT_ADDRESS);
    const {createNft} = useTransactionService(contractAddress);
    const ipfsService = useIpfsService();
    const [metadata, setMetadata] = useState<string>('');

    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/");
        }
    }, [isLoggedIn, navigate]);

    useEffect(() => {
        if (royalties && price && name && imageUrl) {
            setIsButtonDisabled(false);
        } else {
            setIsButtonDisabled(true);
        }
    }, [royalties, price, name, imageUrl]);

    useEffect(() => {
        ipfsService.uploadDescription(description).then((ipfsUrl) => {
            setMetadata(ipfsUrl);
        }).catch((error) => {
            console.error("Error uploading data to IPFS:", error);
        });
    }, [description]);

    const clearState = () => {
        setRoyalties(royaltiesOptions[0])
        setPrice('')
        setName('')
        setImageUrl('')
    }

    const handleTransaction = async () => {
        await createNft(
            name,
            Number(royalties.replace(/%/g, "")),
            imageUrl,
            Number(price),
            metadata
        );
    };

    return (
        <>
            <Control className={styles.control} item={breadcrumbs}/>
            <div className={`section ${styles.section}`}>
                <div className={`container ${styles.container}`}>
                    <div className={styles.form}>
                        <div className={styles.head}>
                            <div className={`h2 ${styles.title}`}>
                                <div>Create single NFT</div>

                                <Link
                                    className={`button-stroke button-small ${styles.button}`}
                                    to="/multi-upload"
                                >
                                    <span>Switch to Multi Upload</span>
                                </Link>
                            </div>
                        </div>
                        <form className={styles.form} action="">
                            <div className={styles.list}>
                                <div className={styles.item}>
                                    <div className={styles.category}>Upload file</div>
                                    <div className={styles.note}>
                                        Drag or choose your file to upload
                                    </div>
                                    <UploadFile ipfsUrl={setImageUrl}/>
                                </div>
                                <div className={styles.item}>
                                    <div className={styles.category}>Item Details</div>
                                    <div className={styles.fieldset}>
                                        <TextInput
                                            onInputChange={setName}
                                            className={styles.field}
                                            value={name}
                                            label="Item name"
                                            name="Item"
                                            type="text"
                                            placeholder='e. g. Redeemable Bitcoin Card with logo'
                                            required
                                        />
                                        <div className={styles.field}>
                                            <div className={styles.label}>Description</div>
                                            <textarea
                                                className={styles.textarea}
                                                rows={2}
                                                cols={150}
                                                value={description}
                                                onChange={(event) => setDescription(event.target.value)}
                                                placeholder="e. g. some item description"
                                                wrap="soft"
                                                name="description"
                                                minLength={150}
                                                maxLength={1500}
                                            />
                                        </div>
                                        <TextInput
                                            onInputChange={setPrice}
                                            className={styles.field}
                                            value={price}
                                            label="Price"
                                            name="Price"
                                            type="text"
                                            placeholder="e. g. 2.5"
                                            required
                                        />
                                        <div className={styles.row}>
                                            <div className={styles.col}>
                                                <div className={styles.field}>
                                                    <div className={styles.label}>Royalties</div>
                                                    <Dropdown
                                                        className={styles.dropdown}
                                                        value={royalties}
                                                        setValue={setRoyalties}
                                                        options={royaltiesOptions}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.foot}>
                                <button
                                    className={`button ${styles.button} ${isButtonDisabled ? 'disabled' : ''}`}
                                    onClick={handleTransaction}
                                    type="button"
                                >
                                    <span>Create item</span>
                                    <Icon name="arrow-next" size="10"/>
                                </button>
                            </div>
                        </form>
                    </div>
                    <Preview
                        className={`${styles.preview} ${visiblePreview ? styles.active : ''}`}
                        imageUrl={imageUrl}
                        title={name}
                        price={price}
                        clearAll={clearState}
                        onClose={() => setVisiblePreview(false)}
                    />
                </div>
            </div>
        </>
    );
};

export default Upload;
