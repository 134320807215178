import React, {useEffect, useState} from 'react';
import './NFTItem.css';
import {useGetIsLoggedIn, useTrackTransactionStatus} from "@multiversx/sdk-dapp/hooks";
import {NFTResponse} from "../../interfaces/nft-response";
import {Link} from "react-router-dom";
import NFTService from "../../services/nft.service";

interface Props {
    nft: NFTResponse;
    showModal: () => void;
    updateList: () => void;
}

const NFTItem = ({nft, showModal, updateList}: Props) => {
    const [sessionId, setTransactionSessionId] = useState<string | null>(null);
    const [price, setPrice] = useState('');
    const [egld, setEgld] = useState('');
    const [tokenIdentifier, setTokenIdentifier] = useState('');
    const isLoggedIn = useGetIsLoggedIn();

    useEffect(() => {
        const fetchData = async () => {
            const {price, tokenIdentifier, egld} = await NFTService.showPrice(nft.nonce);
            setPrice(price);
            setTokenIdentifier(tokenIdentifier);
            setEgld(egld);
        };
        fetchData();
    }, [nft.nonce]);

    const onSuccess = () => {
        console.log('Transaction was successful!');
        updateList()
    };

    const transactionStatus = useTrackTransactionStatus({
        transactionId: sessionId,
        onSuccess,
    });

    const handleClick = async (nonce: number, egld: number) => {
        if (isLoggedIn) {
            const sessionId = await NFTService.buyNFT(nonce, egld);
            setTransactionSessionId(String(sessionId))

            if (transactionStatus.isSuccessful) {
                updateList()
            }
        } else {
            showModal()
        }
    };

    return (
        <div className="uni-artwork uk-overflow-hidden">
            <Link key={nft.identifier} to={`/item/${nft.identifier}`}>
                <div className="uni-artwork-featured-image uk-panel">
                    <div className="uk-panel nft-container">
                        <img src={nft.url}
                             alt={nft.name}
                             className="nft"
                             loading="lazy"/>
                    </div>
                </div>
            </Link>

            <div
                className="uni-artwork-content uk-panel uk-flex-column uk-flex-middle">
                {/* <div className="uk-text-meta uk-margin-xsmall-top">Nonce: {nft.nonce}</div> */}
                <h2 className="uk-h6">
                    {nft.name}
                </h2>
                {/* <div className="uk-text-meta uk-margin-xsmall-top">Nonce: {nft.nonce}</div> */}

                <div className="container-buy-item">
                    <div className="left-column">
                        <div className="title">PRICE</div>
                        <div className="price">{price} {tokenIdentifier}</div>
                    </div>
                    <div className="right-column">
                        <button
                            className="buy-button uk-button uk-button-primary uk-margin-auto"
                            onClick={() => handleClick(nft.nonce, Number(egld))}
                        >
                            Buy
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NFTItem;
