import {NFTResponse} from "../interfaces/nft-response";

export const fetchNFT = async (
    contractAddress: string,
    nft: string,
): Promise<NFTResponse> => {
    const url = `${process.env.REACT_APP_API_URL}/api/accounts/${contractAddress}/nfts/${nft}`;
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching NFT:', nft, error);
        throw error;
    }
};