import React, { useState } from 'react';
import Icon from '../Icon';
import styles from "./UploadFiles.module.sass";
import useIpfsService from "../../services/ipfs.service";
import Loader from "../Loader";

interface Props {
    ipfsUrl: (url: string, name: string) => void
}

const UploadFiles = ({ipfsUrl}: Props) => {
    const ipfsService = useIpfsService();
    const [isLoading, setIsLoading] = useState(false);

    const handleFileUpload = async (event: any) => {
        setIsLoading(true);
        const uploadedFiles = event.target.files;

        if (!uploadedFiles) return;

        try {
            // @ts-ignore
            await Promise.all(Array.from(uploadedFiles).map(async (file: File) => {
                const { url, name } = await ipfsService.uploadFile(file);
                console.log('File uploaded to IPFS:', url, 'Name:', name);
                ipfsUrl(url, name);
                return url;
            }));
        } catch (error) {
            console.error('Error uploading files to IPFS:', error);
        }
        setIsLoading(false);
    };

    return (
        <div className={styles.file}>
            {isLoading ? <Loader className={styles.loader}/> :
                <>
                    <input className={styles.load} type="file" onChange={handleFileUpload} multiple />
                    <div className={styles.icon}>
                        <Icon name="upload-file" size="24"/>
                    </div>
                    <div className={styles.format}>
                        PNG, GIF, WEBP, MP4 or MP3. Max 1Gb.
                    </div>
                </>}
        </div>
    );
};

export default UploadFiles;
