import React from 'react';

const About = () => {
    return (
        <div id="uni_about" className="uni-about uk-section uk-section-xlarge@m uk-panel">
            <div className="uk-container">
                <header className="uk-grid-xsmall uk-flex-center uk-flex-middle uk-grid uk-grid-stack">
                    <div className="uk-panel uk-text-center uk-first-column">
                        <h2 className="uk-h3 uk-h1@m">About</h2>
                    </div>
                </header>
                <div className="uk-panel uk-margin-medium-top uk-margin-2xlarge-top@m">
                    <div className="uk-grid uk-grid-2xlarge uk-grid-3xlarge@m uk-child-width-1-1 uk-grid-stack"
                         data-uk-grid="">
                        <div className="uk-first-column">
                            <div className="uk-panel">
                                <div
                                    className="uk-grid uk-grid-3xlarge@m uk-flex-middle uk-child-width-1-2@m uk-grid-stack"
                                    data-uk-grid="">
                                    <div className="uk-first-column">
                                        <div
                                            className="uk-panel uk-image-middle uk-overflow-hidden uk-radius uk-radius-large@m">
                                            <img src="images/features-03.png" alt="Create your own NFT"/>
                                        </div>
                                    </div>
                                    <div className="uk-grid-margin uk-first-column">
                                        <div className="uk-panel">
                                            <span className="uk-text-overline uk-text-gradient">Setup and Deploy</span>
                                            <h3 className="uk-h3 uk-h1@m">Deploy your Landing Page</h3>
                                            <p className="uk-text-large@m">Own your NFT sales with a custom Landing Page and Contract, sell freely, earn more, and maintain full control.</p>
                                            <div
                                                className="uk-grid uk-grid-large@m uk-grid-match uk-child-width-1-2 uk-margin-large-top@m"
                                                data-uk-grid="">
                                                <div className="uk-first-column">
                                                    <div className="uk-panel">
                                                        <h5 className="uk-h4 uk-h2@m uk-margin-xsmall">{"3x"}</h5>
                                                        <span className="uk-text-small@m">Deploy your own Landing Page in just a few clicks</span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="uk-panel">
                                                        <h5 className="uk-h4 uk-h2@m uk-margin-xsmall">2.5x</h5>
                                                        <span
                                                            className="uk-text-small@m">Difference in Floor &amp; Estimated Value</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="uk-grid-margin uk-first-column">
                            <div className="uk-panel">
                                <div
                                    className="uk-grid uk-grid-3xlarge@m uk-flex-middle uk-child-width-1-2@m uk-grid-stack"
                                    data-uk-grid="">
                                    <div className="uk-first-column">
                                        <div
                                            className="uk-panel uk-image-middle uk-overflow-hidden uk-radius uk-radius-large@m">
                                            <img src="images/features-02.png" alt="Create your own NFT"/>
                                        </div>
                                    </div>
                                    <div className="uk-flex-first@m uk-first-column uk-grid-margin">
                                        <div className="uk-panel"
                                             data-anime="opacity:[0, 1]; translateX:[-24, 0]; onview: -250; delay: 400;"
                                        >
                                            <span className="uk-text-overline uk-text-gradient">Control and Ownership</span>
                                            <h3 className="uk-h3 uk-h1@m">Your DApp, Your Smart Contracts</h3>
                                            <p className="uk-text-large@m">Unrestricted Sovereignty: Take charge of your DApp and smart contracts. With our wizard, it's all yours – no middlemen, no limits.</p>
                                            <div
                                                className="uk-grid uk-grid-large@m uk-grid-match uk-child-width-1-2@m uk-margin-medium-top uk-margin-large-top@m uk-grid-stack"
                                                data-uk-grid="">
                                                <div className="uk-first-column">
                                                    <div className="uk-panel">
                                                        <div className="uk-grid uk-grid-xsmall uk-grid-small@m"
                                                             data-uk-grid="">
                                                            <div className="uk-first-column">
                                                                <div
                                                                    className="uk-card uk-card-xsmall uk-radius-medium uk-radius-large@m uk-background-gradient uk-flex-middle uk-flex-center">
                                                                    <i className="uk-icon-small uk-icon-medium@m unicon-select-02 uk-text-white"></i>
                                                                </div>
                                                            </div>
                                                            <div className="uk-width-expand">
                                                                    <span
                                                                        className="uk-text-small@m">No middleman, your users, your rules</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="uk-grid-margin uk-first-column">
                                                    <div className="uk-panel">
                                                        <div className="uk-grid uk-grid-xsmall uk-grid-small@m"
                                                             data-uk-grid="">
                                                            <div className="uk-first-column">
                                                                <div
                                                                    className="uk-card uk-card-xsmall uk-radius-medium uk-radius-large@m uk-background-gradient uk-flex-middle uk-flex-center">
                                                                    <i className="uk-icon-small uk-icon-medium@m unicon-select-window uk-text-white"></i>
                                                                </div>
                                                            </div>
                                                            <div className="uk-width-expand">
                                                                    <span
                                                                        className="uk-text-small@m">Fully custom theme on request</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
