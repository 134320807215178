import React, {useEffect, useState} from "react";
import styles from "./Item.module.sass";
import "./Item.css"
import {NFTResponse} from "../../interfaces/nft-response";
import {fetchNFT} from "../../multiversx-api/fetch-nft";
import {useParams} from "react-router-dom";
import {
    decodeBase64,
} from "@multiversx/sdk-dapp/utils";
import {useGetIsLoggedIn} from "@multiversx/sdk-dapp/hooks";
import NFTService from "../../services/nft.service";
import Header from "../../components/Header";
import Footer from "../../components/Footer/Footer";
import Modal from "../../components/Modal/Modal";
import Loader from "../../components/Loader";
import Circle from "../../components/Circle";

const contractAddress = String(process.env.REACT_APP_CONTRACT_ADDRESS)
const explorerUrl = String(process.env.REACT_APP_EXPLORER);

const Item = () => {
    console.log('item')
    const {id} = useParams();
    const [nftData, setNftData] = useState({
        nft: {} as NFTResponse,
        description: '',
        price: '',
        egld: '',
        tokenIdentifier: '',
        isLoading: true,
    });
    const categories = [
        {
            category: "black",
            content: `nonce: ${nftData?.nft?.nonce}`,
        },
    ];
    const isLoggedIn = useGetIsLoggedIn();
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const hideModal = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        console.log(3)
        fetchItem()
    }, []);

    const fetchItem = async () => {
        console.log(4)
        try {
            let description = '';
            const fetchedNFT = await fetchNFT(contractAddress, String(id));
            const url = decodeBase64(String(fetchedNFT?.attributes)).replace(/^[^h]*http/i, 'http')
            if (url.includes("http")) {
                description = await fetchData(url);
            }
            console.log(fetchedNFT)

            const {egld, price, tokenIdentifier} = await NFTService.showPrice(Number(fetchedNFT?.nonce));
            setNftData(prevState => ({
                ...prevState,
                nft: fetchedNFT,
                description: description,
                price: price,
                egld: egld,
                tokenIdentifier: tokenIdentifier,
                isLoading: false,
            }));
        } catch (error) {
            console.error('Failed to fetch NFTs:', error);
        }
    };

    const fetchData = async (url: string): Promise<string> => {
        let description = '';
        console.log(4.1)
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            description = await response.text();
        } catch (error) {
            console.error('Error fetching data:', error);
        }

        return description
    };

    const buyNFT = async (nonce: number, value: number) => {
        await NFTService.buyNFT(nonce, value);
    };

    const handleClick = async (nonce: number, price: number) => {
        if (isLoggedIn) {
            await buyNFT(nonce, price);
        } else {
            showModal()
        }
    };

    return (
        <div className="wrap">
            <div style={{
                display: isModalVisible ? 'none' : 'block'
            }}>
                <Header handleModal={showModal} isHomepage={false}/>
                {nftData.isLoading ? <div className={styles.loader}><Loader className=""/></div> :
                    <div className={`section ${styles.section}`}>
                        <div className={`container ${styles.container}`}>
                            <div className={styles.bg}>
                                <div className={styles.preview}>
                                    <div className={styles.categories}>
                                        {categories.map((x, index) => (
                                            <div
                                                className={`status
                    ${x.category === "black" ? 'status-black' : ''}
                    ${x.category === "purple" ? 'status-purple' : ''}
                    ${styles.category}
                    `}
                                                key={index}
                                            >
                                                {x.content}
                                            </div>
                                        ))}
                                    </div>
                                    <img
                                        src={nftData.nft?.url}
                                        alt="Item"
                                        className="item-image"
                                    />
                                </div>
                            </div>
                            <div className={styles.details}>
                                <h1 className={`h3 ${styles.title}`}>{nftData.nft?.name}</h1>
                                <div className={styles.cost}>
                                    <div className={`status-stroke-green ${styles.price}`}>
                                        {nftData.price} {nftData.tokenIdentifier}
                                    </div>
                                    {/* <div className={`status-stroke-black ${styles.price}`}>
                                        $4,429.87
                                    </div> */}
                                </div>
                                {nftData.description ?
                                    <>
                                        <h1 className="uk-h6 uk-margin-remove-top">Description</h1>
                                        <div className={styles.info}>
                                            {nftData.description}
                                        </div>
                                    </>
                                    : ''}
                                <h1 className="uk-h6 uk-margin-remove-top">Details</h1>
                                <ul className={`${styles.list} details`}>
                                    <li><span className="circle-container"><Circle/> Royalties</span>
                                        <span>{nftData.nft.royalties}%</span></li>
                                    <li><span className="circle-container"><Circle/> Token ID</span>
                                        <a href={`${explorerUrl}nfts/${nftData.nft.identifier}`}
                                           className="plain-link"
                                           target="_blank">
                                            {nftData.nft.identifier}
                                        </a>
                                    </li>
                                </ul>

                                <button
                                    className="nft-buy-button uk-button uk-button-primary"
                                    onClick={() => handleClick(Number(nftData.nft?.nonce), Number(nftData.egld))}
                                >
                                    Buy now
                                </button>
                            </div>
                        </div>
                    </div>}
                <Footer/>
            </div>

            <Modal isModalVisible={isModalVisible} hideModal={hideModal}/>
        </div>
    );
};

export default Item;
