import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer>
            <hr className="uk-margin-medium uk-margin-3xlarge-top@m" />
            <div className="uk-panel uk-text-small">
                <div className="uk-grid uk-child-width-auto@m uk-flex-center uk-flex-between@m uk-text-center uk-text-left@m" data-uk-grid="">
                    {/* <div>
                        <ul className="uk-subnav uk-subnav-small uk-text-muted uk-flex-center">
                            <li><a href="/ui8/nerko/main/privacy">Privacy policy</a></li>
                            <li><a href="/ui8/nerko/main/terms">Terms of use</a></li>
                        </ul>
                    </div> */}
                    <div className="uk-flex-first@m uk-flex-center uk-first-column">
                        <p className="uk-text-muted">© 2024 xNFTLandr. All rights reserved.</p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
