import {sendTransactions} from "@multiversx/sdk-dapp/services";
import {ConvertArgument} from "../helpers/convert-argument";
import json from "../contract/nft-minter.abi.json";
import queryContract from "../multiversx-api/query-contract";
import {ResultsParser, TokenTransfer} from "@multiversx/sdk-core/out";
import {SmartContract, Address, AbiRegistry} from "@multiversx/sdk-core";

interface NFTService {
    buyNFT: (nonce: number, value: number) => Promise<string | undefined>;
    showPrice: (nonce: number) => Promise<{
        price: string,
        egld: string,
        tokenIdentifier: string
    }>;
}
const contractAddress = String(process.env.REACT_APP_CONTRACT_ADDRESS)

const NFTService: NFTService = {
    buyNFT: async (nonce: number, value: number): Promise<string | undefined> => {
        console.log(nonce, value, contractAddress)
        try {
            const { sessionId, error } = await sendTransactions({
                transactions: [
                    {
                        value, // Value of the transaction (in wei)
                        data: 'buyNft' + "@" + ConvertArgument.numericValue(nonce),
                        gasLimit: 60000000,
                        receiver: contractAddress,
                    },
                ],
                callbackRoute: '/',
            });

            if (error) {
                console.error('Error sending transaction:', error);
            } else {
                console.log('Transaction sent successfully. Session ID:', sessionId);
            }

            return sessionId
        } catch (error) {
            console.error('Error sending transaction:', error);
        }

        return undefined;
    },

    showPrice: async (nonce: number) => {
        const contract = new SmartContract({
            address: new Address(contractAddress),
            abi: AbiRegistry.create(json)
        });
        const endpointDefinition = contract.getEndpoint("getNftPrice");
        const queryResponse = await queryContract(contractAddress, 'getNftPrice', [ConvertArgument.numericValue(nonce)]);
        const {values} = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);
        const floatValue = parseFloat(TokenTransfer.egldFromBigInteger(values[0].valueOf()[2]).toPrettyString());
        const price = String(floatValue);

        return {
            price,
            egld: values[0].valueOf()[2].toFixed(0),
            tokenIdentifier: values[0].valueOf()[0]?.toString(10)
        }
    }
};

export default NFTService;
