import React from 'react';

const Roadmap = () => {
    return (
        <div id="uni_roadmap" className="uni-roadmap uk-section uk-section-large@m uk-panel uk-overflow-hidden">
            <div className="uk-container uk-container-small">
                <header
                    className="uk-grid-xsmall uk-flex-center uk-flex-middle uk-text-center uk-child-width-auto@m uk-grid uk-grid-stack"
                    data-uk-grid="">
                    <div className="uk-panel uk-first-column">
                        <h2 className="uk-h3 uk-h1@m">xNFTLandr Roadmap</h2>
                    </div>
                </header>
                <div className="uk-panel uk-margin-medium-top uk-margin-2xlarge-top@m">
                    <div
                        className="uk-grid-column-large uk-grid-row-collapse@m uk-grid-divider uk-child-width-1-2@m uk-text-muted uk-grid"
                        data-uk-grid="">
                        <div className="uk-first-column">
                            <div
                                className="uni-phase uk-panel uk-card uk-card-small uk-card-medium@m uk-radius uk-radius-xlarge@m uk-box-shadow-xsmall dark:uk-background-white-5">
                                <span
                                    className="uni-phase-progress uk-text-small uk-text-bold uk-position-top-right uk-position-small uk-text-white uk-background-gradient"><span>10</span>%</span>
                                <div className="uk-panel">
                                    <span className="uni-phase-count uk-text-overline uk-text-gradient">Phase 01</span>
                                    <h3 className="uni-phase-title uk-h4 uk-h3@m uk-margin-remove-bottom">Initial Release (Current)</h3>
                                    <p className="uni-phase-description uk-text-large@m">First version of our DApp and smart contracts for user testing.
                                        Some cool stuff's ready, more coming!</p>
                                </div>
                            </div>
                        </div>
                        <div className="uk-visible@m">
                            <canvas height="245"></canvas>
                        </div>
                        <div className="uk-visible@m uk-grid-margin uk-first-column">
                            <canvas height="245"></canvas>
                        </div>
                        <div className="uk-grid-margin">
                            <div
                                className="uni-phase uk-panel uk-card uk-card-small uk-card-medium@m uk-radius uk-radius-xlarge@m uk-box-shadow-xsmall dark:uk-background-white-5 uni-phase-even">
                                <span
                                    className="uni-phase-progress uk-text-small uk-text-bold uk-position-top-right uk-position-small uk-text-white uk-background-gradient"><span>25</span>%</span>
                                <div className="uk-panel">
                                    <span className="uni-phase-count uk-text-overline uk-text-gradient">Phase 02</span>
                                    <h3 className="uni-phase-title uk-h4 uk-h3@m uk-margin-remove-bottom">Wizarding Wonders</h3>
                                    <p className="uni-phase-description uk-text-large@m">Get ready to wield your wizarding skills as we introduce the setup and deployment wizard! We're polishing the dApp, thanks to your feedback!</p>
                                </div>
                            </div>
                        </div>
                        <div className="uk-grid-margin uk-first-column">
                            <div
                                className="uni-phase uk-panel uk-card uk-card-small uk-card-medium@m uk-radius uk-radius-xlarge@m uk-box-shadow-xsmall dark:uk-background-white-5">
                                <span
                                    className="uni-phase-progress uk-text-small uk-text-bold uk-position-top-right uk-position-small uk-text-white uk-background-gradient"><span>50</span>%</span>
                                <div className="uk-panel">
                                    <span className="uni-phase-count uk-text-overline uk-text-gradient">Phase 03</span>
                                    <h3 className="uni-phase-title uk-h4 uk-h3@m uk-margin-remove-bottom">Auction Feature</h3>
                                    <p className="uni-phase-description uk-text-large@m">Time to bid! Auctions unleashed for NFT sales. Smarter contracts, better auctions. DApp performance souped up for your bidding spree!</p>
                                </div>
                            </div>
                        </div>
                        <div className="uk-visible@m uk-grid-margin">
                            <canvas height="245"></canvas>
                        </div>
                        <div className="uk-visible@m uk-grid-margin uk-first-column">
                            <canvas height="245"></canvas>
                        </div>
                        <div className="uk-grid-margin">
                            <div
                                className="uni-phase uk-panel uk-card uk-card-small uk-card-medium@m uk-radius uk-radius-xlarge@m uk-box-shadow-xsmall dark:uk-background-white-5 uni-phase-even">
                                <span
                                    className="uni-phase-progress uk-text-small uk-text-bold uk-position-top-right uk-position-small uk-text-white uk-background-gradient"><span>75</span>%</span>
                                <div className="uk-panel">
                                    <span className="uni-phase-count uk-text-overline uk-text-gradient">Phase 04</span>
                                    <h3 className="uni-phase-title uk-h4 uk-h3@m uk-margin-remove-bottom">Theme-tastic</h3>
                                    <p className="uni-phase-description uk-text-large@m">Dress your DApp in style! Get ready for 10 unique themes, tailored just for you. It's your DApp, your vibe!</p>
                                </div>
                            </div>
                        </div>
                        <div className="uk-grid-margin uk-first-column">
                            <div
                                className="uni-phase uk-panel uk-card uk-card-small uk-card-medium@m uk-radius uk-radius-xlarge@m uk-box-shadow-xsmall dark:uk-background-white-5">
                                <span
                                    className="uni-phase-progress uk-text-small uk-text-bold uk-position-top-right uk-position-small uk-text-white uk-background-gradient"><span>99</span>%</span>
                                <div className="uk-panel">
                                    <span className="uni-phase-count uk-text-overline uk-text-gradient">Phase 05</span>
                                    <h3 className="uni-phase-title uk-h4 uk-h3@m uk-margin-remove-bottom">User Empowerment</h3>
                                    <p className="uni-phase-description uk-text-large@m">Bringing your wishes to life! Phase: User Dreams Come True.
                                        Your feedback fuels our features. Let's make magic together!</p>
                                </div>
                            </div>
                        </div>
                        <div className="uk-visible@m uk-grid-margin">
                            <canvas height="245"></canvas>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Roadmap;