interface Address {
    address: string;
    deployTxHash: string;
    timestamp: number;
}

export const fetchContracts = async (
    userAddress: string
): Promise<Address[]> => {
    const url = `${process.env.REACT_APP_API_URL}/api/accounts/${userAddress}/contracts`
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching contracts:', error);
        throw error;
    }
};