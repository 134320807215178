import React, {useEffect, useState} from 'react';
import {useGetAccountInfo, useGetLoginInfo} from "@multiversx/sdk-dapp/hooks";
import {logout} from "@multiversx/sdk-dapp/utils";
import {Link} from 'react-router-dom';
import {fetchContracts} from "../multiversx-api/fetch-contracts";

const contractAddress = String(process.env.REACT_APP_CONTRACT_ADDRESS)

interface Props {
    handleModal: (modalOpen: boolean) => void;
    isHomepage: boolean;
}

const Header = ({handleModal, isHomepage}: Props) => {
    const [modalOpen, setModalOpen] = useState(false);
    const loginInfo = useGetLoginInfo();
    const accountInfo = useGetAccountInfo();
    const [isOwner, setIsOwner] = useState<boolean>(false);

    useEffect(() => {
        if (loginInfo.isLoggedIn) {
            fetchUserContracts()
        }
    }, []);

    const fetchUserContracts = async () => {
        try {
            const contracts = await fetchContracts(accountInfo?.address)
            const exists = contracts.some(obj => obj.address === contractAddress);
            setIsOwner(exists)
        } catch (error) {
            console.error('Failed to fetch NFT count:', error);
        }
    }

    const onModalToggle = () => {
        if (loginInfo.isLoggedIn) {
            logout('/');
        } else {
            setModalOpen(!modalOpen);
            handleModal(!modalOpen);
        }
    };

    const getShortenedAddress = () => {
        return (accountInfo.address).substring(0, 20) + '...'
    }

    return (
        <header className="uni-header uk-dark">
            <div className="uni-header-navbar"
                 data-uk-sticky="top: 70; show-on-up: false; animation: uk-animation-slide-top"
                 data-anime="opacity:[0, 1]; translateY:[-24, 0]; onview: true; delay: 0;">
                <div className="uk-container">
                    <nav className="uk-navbar uk-navbar-container uk-navbar-transparent">
                        <div className="uk-navbar-top">
                            <div className="uk-navbar-left">
                                <Link to={'/'} className="uk-logo uk-navbar-item uk-h4 uk-h3@m uk-margin-remove">
                                    xNFTLandr
                                </Link>
                            </div>

                            <div className="uk-navbar-right uk-flex-1 uk-flex-right">
                                <ul className="uk-navbar-nav dark:uk-text-gray-10 uk-visible@m"
                                    data-uk-scrollspy-nav="closest: li; scroll: true; offset: 80">
                                    <li><Link to={'/'}>Home</Link></li>
                                    {isHomepage ? <>
                                        <li><a href="#uni_collection">Collection</a></li>
                                        <li><a href="#uni_roadmap">Roadmap</a></li>
                                        <li><a href="#uni_faq">FAQ</a></li>
                                    </> : ''}
                                    {isOwner ? <li><Link to="/upload">Upload</Link></li> : ''}
                                </ul>
                                <div className="uk-navbar-item">
                                    <a href="#uni_connect_wallet"
                                       className="uk-button uk-button-medium@m uk-button-default uk-button-outline uk-margin-left uk-visible@l"
                                       onClick={onModalToggle}
                                       data-uk-toggle="">
                                        <span>{loginInfo.isLoggedIn ? getShortenedAddress() : 'Connect wallet'}</span>
                                    </a>
                                </div>

                                <a href="#uni_connect_wallet"
                                   onClick={onModalToggle}
                                   className="uk-button uk-button-medium@m uk-button-icon uk-hidden@l uk-margin-small-right"
                                   data-uk-toggle="">
                                    <i className="uk-icon unicon-wallet"></i>
                                </a>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </header>
    );
};

export default Header;
