import styles from "./MultiUpload.module.sass";
import Dropdown from "../../components/Dropdown";
import React, {useState} from "react";
import TextInput from "../../components/TextInput";

interface Props {
    onRoyaltiesChange: (url: string) => void
    onDefaultPriceChange: (url: string) => void
}

export const Fields = ({onRoyaltiesChange, onDefaultPriceChange}: Props) => {
    const royaltiesOptions = ["10%", "20%", "30%"];
    const [royalties, setRoyalties] = useState(royaltiesOptions[0]);
    const [price, setPrice] = useState<string>('');

    const handleRoyaltiesChange = (value: string) => {
        setRoyalties(value);
        if (onRoyaltiesChange) {
            onRoyaltiesChange(value);
        }
    };

    const handlePriceChange = (value: string) => {
        setPrice(value);
        if (onDefaultPriceChange) {
            onDefaultPriceChange(value);
        }
    };

    return (<div className={styles.item}>
        <div className={styles.fieldset}>
            <div className={styles.row}>
                <div className={styles.col}>
                    <div className={styles.field}>
                        <div className={styles.label}>Royalties</div>
                        <Dropdown
                            className={styles.dropdown}
                            value={royalties}
                            setValue={handleRoyaltiesChange}
                            options={royaltiesOptions}
                        />
                    </div>
                </div>

                <div className={styles.col}>
                    <div className={styles.field}>
                        <div className={styles.label}>Set Price for all</div>
                        <TextInput
                            onInputChange={handlePriceChange}
                            className={styles.field}
                            addon="EGLD"
                            value={price}
                            name="Price"
                            type="text"
                            placeholder="Item Price e. g. 2.5"
                            required/>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}