import CollectionGrid from "../../components/CollectionGrid/CollectionGrid";
import Header from "../../components/Header";
import React, {useEffect, useState} from "react";
import Footer from "../../components/Footer/Footer";
import Modal from "../../components/Modal/Modal";
import {NFTResponse} from "../../interfaces/nft-response";
import {fetchNFTs} from "../../multiversx-api/fetch-nfts";
import {fetchNFTCount} from "../../multiversx-api/fetch-nft-count";
import './CollectionOverview.css';

const contractAddress = String(process.env.REACT_APP_CONTRACT_ADDRESS)
const collection = String(process.env.REACT_APP_COLLECTION)

const CollectionOverview = () => {
    const [nfts, setNFTs] = useState<NFTResponse[]>([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [nftCount, setNFTCount] = useState<number>(0);
    const [fetchedNftCount, setFetchedNFTCount] = useState<number>(0);
    const [from, setFrom] = useState<number>(0);

    useEffect(() => {
        fetchList()
        fetchCount()
    }, []);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const hideModal = () => {
        setIsModalVisible(false);
    };

    const fetchCount = async () => {
        try {
            const count = await fetchNFTCount(contractAddress, collection, 'NonFungibleESDT')
            setNFTCount(count)
        } catch (error) {
            console.error('Failed to fetch NFT count:', error);
        }
    }

    const fetchList = async () => {
        try {
            const fetchedNFTs = await fetchNFTs(contractAddress, collection, 'NonFungibleESDT', from);
            setNFTs(fetchedNFTs);
            setFetchedNFTCount(nfts.length + fetchedNFTs.length)
        } catch (error) {
            console.error('Failed to fetch NFTs:', error);
        }
    };

    const updateList = () => {
        fetchList()
    }

    const handleLoadMore = async () => {
        const updatedFrom = from + 12
        try {
            const fetchedNFTs = await fetchNFTs(contractAddress, collection, 'NonFungibleESDT', updatedFrom);

            setNFTs(prevState => [ ...prevState, ...fetchedNFTs]);
            setFrom(updatedFrom);
            setFetchedNFTCount(nfts.length + fetchedNFTs.length)
        } catch (error) {
            console.error('Failed to fetch NFTs:', error);
        }
    };

    return (
        <div className="wrap">
            <div style={{
                display: isModalVisible ? 'none' : 'block'
            }}>
                <Header handleModal={showModal} isHomepage={false}/>
                <div className="uk-container">
                    <header
                        className="collection-heading uk-grid-xsmall uk-flex-center uk-flex-middle uk-text-center uk-child-width-auto@m uk-grid uk-grid-stack"
                        data-uk-grid="">
                        <div className="uk-panel uk-first-column">
                            <h2 className="uk-h3 uk-h1@m">Demo Collection</h2>
                        </div>
                    </header>
                <CollectionGrid showModal={showModal} updateList={updateList} nfts={nfts} inline={false}/>
                {nftCount !== fetchedNftCount ?
                    <div onClick={handleLoadMore} className="uni-btn uk-margin-medium-top uk-margin-2xlarge-top@m uk-flex-center">
                        <div className="uk-button uk-button-small uk-button-large@m uk-button-gradient">
                            <span>Load more</span>
                        </div>
                    </div> : ''}
                </div>
                <Footer/>
            </div>

            <Modal isModalVisible={isModalVisible} hideModal={hideModal}/>
        </div>
    )
}

export default CollectionOverview