import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./Dropdown.module.sass";
import Icon from "../Icon";

// @ts-ignore
const Dropdown = ({ className, value, setValue, options }) => {
  const [visible, setVisible] = useState(false);

  const handleClick = (value: any) => {
    setValue(value);
    setVisible(false);
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div
        className={`${styles.dropdown} ${className} ${visible ? styles.active : ''}`}
      >
        <div className={styles.head} onClick={() => setVisible(!visible)}>
          <div className={styles.selection}>{value}</div>
          <div className={styles.arrow}>
            <Icon name="arrow-bottom" size="10" />
          </div>
        </div>
        <div className={styles.body}>
          {options.map((x: any, index: number) => (
            <div
              className={`${styles.option} ${x === value ? styles.selectioned : ''}`}
              onClick={() => handleClick(x)}
              key={index}
            >
              {x}
            </div>
          ))}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default Dropdown;
