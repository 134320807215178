import React from 'react';
import './Divider.css';

const Divider = () => {
    return (
        <svg width="404" height="34" viewBox="0 0 404 34" fill="none" xmlns="http://www.w3.org/2000/svg"
             className="divider uk-width-small uk-flex-center uk-margin-auto uk-text-primary uk-svg">
            <path opacity="0.2" d="M16 13H6L0 21H16H32H42L48 13H32H16Z"
                  fill="#A7D100"></path>
            <path opacity="0.5" d="M72 13H62L56 21H72H88H98L104 13H88H72Z"
                  fill="#A7D100"></path>
            <path d="M128 13H118L112 21H128H144H154L160 13H144H128Z"
                  fill="#A7D100"></path>
            <path d="M260 13H250L244 21H260H276H286L292 13H276H260Z"
                  fill="#A7D100"></path>
            <path opacity="0.5"
                  d="M316 13H306L300 21H316H332H342L348 13H332H316Z"
                  fill="#A7D100"></path>
            <path opacity="0.2"
                  d="M372 13H362L356 21H372H388H398L404 13H388H372Z"
                  fill="#A7D100"></path>
            <path
                d="M202.012 0.67749V0.67749C204.004 8.73167 210.293 15.0203 218.347 17.0122V17.0122V17.0122C210.088 18.63 203.63 25.0875 202.012 33.3468V33.3468V33.3468C199.925 25.3467 193.678 19.0991 185.678 17.0122V17.0122V17.0122C193.887 15.3018 200.302 8.88717 202.012 0.67749V0.67749Z"
                fill="#A7D100"></path>
        </svg>
    );
};

export default Divider;
