import Marketplace from "./pages/Marketplace";
import Upload from "./pages/Upload/Upload";
import MultiUpload from "./pages/MultiUpload/MultiUpload";
import Dashboard from "./pages/Dashboard";
import Item from "./pages/Item/Item";
import CollectionOverview from "./pages/CollectionOverview/CollectionOverview";

const routes = [
    /*{
        path: '/',
        component: Home,
    },*/
    {
        path: "/",
        title: "Marketplace",
        component: Marketplace,
        authenticatedRoute: true,
    },
    {
        path: "/upload",
        title: "Upload",
        component: Upload,
        authenticatedRoute: true,
    },
    {
        path: "/multi-upload",
        title: "Multi Upload",
        component: MultiUpload,
        authenticatedRoute: true,
    },
    {
        path: "/dashboard",
        title: "Dashboard",
        component: Dashboard,
        authenticatedRoute: true,
    },
    {
        path: "/item/:id", // Use ':id' to specify the parameter
        title: "Item Details",
        component: Item, // Provide the component for the route with parameter
        authenticatedRoute: true,
    },
    {
        path: "/collection",
        title: "Collection",
        component: CollectionOverview,
        authenticatedRoute: true,
    },
];

export default routes;
