import {NFTResponse} from "../interfaces/nft-response";

export const fetchNFTs = async (
    contractAddress: string,
    collections: string,
    type: string,
    from = 0
): Promise<NFTResponse[]> => {
    const url = `${process.env.REACT_APP_API_URL}/api/accounts/${contractAddress}/nfts?collections=${collections}&type=${type}&size=12&from=${from}`
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        return data as NFTResponse[];
    } catch (error) {
        console.error('Error fetching NFTs:', error);
        throw error;
    }
};