import React from "react";
import { Link } from "react-router-dom";
import styles from "./Control.module.sass";
import Icon from "../Icon";

// @ts-ignore
const Control = ({ className, item }) => {
  return (
    <div className={`${styles.control} ${className}`}>
      <div className={`container ${styles.container}`}>
        <Link
          className={`button-stroke button-small ${styles.button}`}
          to="/"
        >
          <Icon name="arrow-prev" size="10" />
          <span>Back to home</span>
        </Link>
        <div className={styles.breadcrumbs}>
          {item.map((x: any, index: number) => (
            <div className={styles.item} key={index}>
              {x.url ? (
                <Link className={styles.link} to={x.url} key={index}>
                  {x.title}
                </Link>
              ) : (
                x.title
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Control;
