const Circle = () => {
    return (
        <svg width="14" height="15" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="5" cy="5.5" r="4" stroke="url(#paint0_linear_26_13711)" strokeWidth="2"/>
            <defs>
                <linearGradient id="paint0_linear_26_13711" x1="0" y1="5.5" x2="10" y2="5.5"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#6345ED"/>
                    <stop offset="0.526042" stopColor="#7F42F0"/>
                    <stop offset="1" stopColor="#DC39FC"/>
                </linearGradient>
            </defs>
        </svg>
    )
}

export default Circle;