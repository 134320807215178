import {File, NFTStorage} from 'nft.storage';
import {StringHelper} from "../helpers/string-helper";

const nftStorageApiToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweENjZjAyQUVhNzE3MzFENDhGMzdCMUQwMDQ3NTkxNThBZmE1RDdlNTAiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTY2NTQwMTgwNTA4NywibmFtZSI6Ik5GVCBBcnRpc3RzIn0.TYBWjIjUT7wm5pt1yXNeTg4s0AhaWTxnctOpGTZM_n0'

export interface IpfsFile {
    url: string;
    name: string;
}

interface IpfsService {
    getClient(): NFTStorage;
    uploadFile(file: File): Promise<IpfsFile>;
    uploadDescription(description: string): Promise<string>;
}

const useIpfsService = (): IpfsService => {
    const getClient = (): NFTStorage => {
        return new NFTStorage({token: nftStorageApiToken});
    };

    const uploadFile = async (file: File): Promise<IpfsFile> => {
        let url = '';
        const ipfsClient = getClient();

        try {
            const metadata = await ipfsClient.store({
                image: file,
                name: file.name,
                description: ''
            });
            const res = metadata.embed() as any;
            url = res.image.href;
        } catch (e) {
            console.error(e);
        }

        const name = StringHelper.capitalizeWords(file.name.replace(/\.[^.]+$/, ""))
        return {
            url,
            name,
        };
    };

    const uploadDescription = async (description: string): Promise<string> => {
        let url = '';
        const ipfsClient = getClient();
        const blob = new Blob([description], { type: 'application/json' });
        const file = new File([blob], 'metadata.json', { type: 'application/json' });

        try {
            const metadata = await ipfsClient.store({
                image: file,
                name: file.name,
                description: ''
            });
            const res = metadata.embed() as any;
            url = res.image.href;
            console.log(res)
            console.log(url)
        } catch (e) {
            console.error(e);
        }

        return url;
    };

    return {
        getClient,
        uploadFile,
        uploadDescription
    };
};

export default useIpfsService;
