import React, {useEffect, useState} from 'react';
import {NFTResponse} from "../interfaces/nft-response";
import {fetchNFTs} from "../multiversx-api/fetch-nfts";
import {fetchNFTCount} from "../multiversx-api/fetch-nft-count";
import CollectionGrid from "./CollectionGrid/CollectionGrid";
import {Link} from "react-router-dom";

const contractAddress = String(process.env.REACT_APP_CONTRACT_ADDRESS)
const collection = String(process.env.REACT_APP_COLLECTION)

interface Props {
    showModal: () => void;
}

const Collection = ({showModal}: Props) => {
    const [nfts, setNFTs] = useState<NFTResponse[]>([]);
    const [nftCount, setNFTCount] = useState<number>(0);

    useEffect(() => {
        fetchList()
        fetchCount()
    }, []);

    const fetchList = async () => {
        try {
            const fetchedNFTs = await fetchNFTs(contractAddress, collection, 'NonFungibleESDT');
            setNFTs(fetchedNFTs);
        } catch (error) {
            console.error('Failed to fetch NFTs:', error);
        }
    };

    const fetchCount = async () => {
        try {
            const count = await fetchNFTCount(contractAddress, collection, 'NonFungibleESDT')
            setNFTCount(count)
        } catch (error) {
            console.error('Failed to fetch NFT count:', error);
        }
    }

    const updateList = () => {
        fetchList()
    }

    return (
        <div id="uni_collection" className="uni-collection uk-panel uk-overflow-hidden uk-padding-2xlarge-bottom@m">
            <div className="uk-container">
                <header
                    className="uk-grid-xsmall uk-flex-center uk-flex-middle uk-text-center uk-child-width-auto@m uk-grid uk-grid-stack"
                    data-uk-grid="">
                    <div className="uk-panel uk-first-column">
                        <h2 className="uk-h3 uk-h1@m">Demo Collection</h2>
                    </div>
                </header>
                <div className="uk-panel uk-margin-top uk-margin-xlarge-top@m uk-position-z-index">
                    <CollectionGrid updateList={updateList} showModal={showModal} nfts={nfts} inline={true}></CollectionGrid>
                    {nftCount >= 12 ?
                        <div className="uni-btn uk-margin-medium-top uk-margin-2xlarge-top@m uk-flex-center">
                            <Link to="/collection"
                               className="uk-button uk-button-small uk-button-large@m uk-button-gradient">
                                <span>Show more</span>
                                <i className="uk-icon-small unicon-arrow-right"></i>
                            </Link>
                        </div> : ''}
                </div>
            </div>
        </div>
    );
};

export default Collection;
