import React from "react";
import styles from "./Loader.module.sass";

// @ts-ignore
const Loader = ({ className, color = '' }) => {
  return (
    <div
    className={`${styles.loader} ${className} ${color === "white" ? styles.loaderWhite : ''}`}
    />
  );
};

export default Loader;
