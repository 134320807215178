import React from 'react';
import './Modal.css'; // Import your CSS file
import {
    ExtensionLoginButton,
    WebWalletLoginButton,
    LedgerLoginButton,
    WalletConnectLoginButton
} from "@multiversx/sdk-dapp/UI";

interface Props {
    isModalVisible: boolean;
    hideModal: () => void;
}

const Modal = ({isModalVisible, hideModal}: Props) => {
    const handleCloseModal = () => {
        hideModal();
    }

    return (
        <div className={`uk-modal-full ${isModalVisible ? 'uk-open' : 'uk-modal'}`} id="uni_connect_wallet"
             data-uk-modal="" style={{display: isModalVisible ? 'block' : 'none'}}>
            <div className="uk-modal-dialog">
                <div className="uk-position-top uk-position-z-index-negative" data-uk-height-viewport=""
                     style={{minHeight: 'calc(100vh)'}}>
                    <div className="uk-position-cover uk-background-cover uk-opacity-10 dark:uk-hidden"
                         style={{backgroundImage: 'url("https://unistudio.co/ui8/nerko/assets/images/gradient-01.png")'}}></div>
                    <div className="uk-position-cover uk-background-cover uk-opacity-20 uk-hidden dark:uk-visible"
                         style={{backgroundImage: 'url("https://unistudio.co/ui8/nerko/assets/images/gradient-01.png")'}}></div>
                </div>
                <button onClick={handleCloseModal}
                        className="uk-modal-close-full uk-close-large uk-position-small uk-icon uk-close" type="button"
                        data-uk-close="">
                    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <line fill="none" stroke="#000" strokeWidth="1.4" x1="1" y1="1" x2="19" y2="19"></line>
                        <line fill="none" stroke="#000" strokeWidth="1.4" x1="19" y1="1" x2="1" y2="19"></line>
                    </svg>
                </button>
                <div className="uk-container">
                    <div className="uk-flex-center uk-flex-middle" data-uk-grid="" data-uk-height-viewport=""
                         style={{minHeight: 'calc(100vh)'}}>
                        <div className="uk-first-column">
                            <div className="uk-panel uk-text-center">
                                <h2 className="uk-h5 uk-h3@s uk-h2@l uk-margin-remove">Connect your wallet</h2>
                                <div className="list">
                                    <div
                                        className="uk-panel uk-card uk-card-border uk-radius-medium uk-radius-large@m uk-box-shadow-hover-small hover:uk-border-primary">
                                        <ExtensionLoginButton
                                            callbackRoute={"/"}
                                            loginButtonText={"DeFi Wallet"}
                                            buttonClassName={"plain-button uk-text-bold uk-h5@m"}
                                            className={""}
                                        />
                                    </div>
                                    <div
                                        className="uk-panel uk-card uk-card-border uk-radius-medium uk-radius-large@m uk-box-shadow-hover-small hover:uk-border-primary">
                                        <WalletConnectLoginButton
                                            callbackRoute={"/"}
                                            loginButtonText={"xPortal"}
                                            buttonClassName={"plain-button uk-text-bold uk-h5@m"}
                                        />
                                    </div>
                                    <div
                                        className="uk-panel uk-card uk-card-border uk-radius-medium uk-radius-large@m uk-box-shadow-hover-small hover:uk-border-primary">
                                        <LedgerLoginButton
                                            loginButtonText={"Ledger"}
                                            callbackRoute={"/"}
                                            buttonClassName={"plain-button uk-text-bold uk-h5@m"}
                                        />
                                    </div>
                                    <div
                                        className="uk-panel uk-card uk-card-border uk-radius-medium uk-radius-large@m uk-box-shadow-hover-small hover:uk-border-primary">
                                        <WebWalletLoginButton
                                            callbackRoute={"/"}
                                            loginButtonText={"Web Wallet"}
                                            buttonClassName={"plain-button uk-text-bold uk-h5@m"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
