import * as React from 'react';
import Header from "../components/Header";
import Hero from "../components/Hero/Hero";
import Divider from "../components/Divider/Divider";
import Roadmap from "../components/Roadmap";
import FAQ from "../components/FAQ/FAQ";
import Modal from "../components/Modal/Modal";
import Footer from "../components/Footer/Footer";
import Collection from "../components/Collection";
import {useState} from "react";
import About from "../components/About/About";

// TODO: update all texts
// TODO: collection overview page
// TODO: check redirects (if user is not logged in -> redirect to actual page not /)
const Marketplace = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const hideModal = () => {
        setIsModalVisible(false);
    };

    return (
        <div className="wrap">
            <div style={{
                display: isModalVisible ? 'none' : 'block' // Conditional text color
            }}>
                <Header handleModal={showModal} isHomepage={true} />
                <Hero/>
                <Divider/>

                <About />
                <Divider/>

                <Collection showModal={showModal} />
                <Roadmap />
                {/* <FAQ /> */}
                <Footer />
            </div>

            <Modal isModalVisible={isModalVisible} hideModal={hideModal} />
        </div>
    );
};

export default Marketplace;
