import BigNumber from "bignumber.js";

export class ConvertArgument {
    static numericValue(intValue: number): string {
        const bn = new BigNumber(intValue, 10);
        let bnStr = bn.toString(16);
        if (bnStr.length % 2 != 0) {
            bnStr = "0" + bnStr;
        }

        return bnStr
    }
}