import {ResponseData} from "../interfaces/response-data";
import {ContractQueryResponse} from "@multiversx/sdk-network-providers/out";

const queryContract = async function fetchData(
    contractAddress: string,
    endpoint: string,
    args: any[] = []
): Promise<ContractQueryResponse> {
    const requestBody = {
        scAddress: contractAddress,
        funcName: endpoint,
        args,
    };

    try {
        const response = await fetch('https://devnet-gateway.multiversx.com/vm-values/query', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        });

        if (!response.ok) {
            throw new Error('Failed to fetch data');
        }

        const responseData: ResponseData = await response.json()
        return new ContractQueryResponse({
            returnData: responseData.data.data.returnData,
            returnCode: responseData.data.data.returnCode,
            returnMessage: responseData.data.data.returnMessage,
        })
    } catch (error: any) {
        console.error('Error fetching data:', error);
        return {
            returnCode: 'error',
            returnMessage: error?.message,
        } as ContractQueryResponse;
    }
}

export default queryContract;