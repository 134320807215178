import React from "react";
import styles from "./Preview.module.sass";
import Icon from "../../../components/Icon";

interface Props {
    className: string,
    imageUrl: string,
    title: string,
    price: string,
    clearAll: () => void
    onClose: () => void,
}

const Preview = ({className, imageUrl, title, price, clearAll, onClose}: Props) => {
    return (
        <div className={`${className} ${styles.wrap}`}>
            <div className={styles.inner}>
                <button className={styles.close} onClick={onClose}>
                    <Icon name="close" size="14"/>
                </button>
                <div className={styles.info}>Preview</div>
                <div className={styles.card}>
                    {imageUrl ? <div className={styles.preview}>
                        <img
                            src={imageUrl}
                            alt="Card"
                        />
                    </div> : ''}
                    <div className={styles.link}>
                        <div className={styles.body}>
                            <div className={styles.line}>
                                {title ? <div className={styles.title}>{title}</div> : ''}
                                {price ? <div className={styles.price}>{price} EGLD</div> : ''}
                            </div>
                        </div>
                        {/* <div className={styles.foot}>
                            <div className={styles.status}>
                                <Icon name="candlesticks-up" size="20"/>
                                Highest bid <span>0.001 ETH</span>
                            </div>
                            <div className={styles.bid}>
                                New bid
                                <span role="img" aria-label="fire"></span>
                            </div>
                        </div> */}
                    </div>
                </div>
                { imageUrl && title && price ?
                    <button className={styles.clear} onClick={clearAll}>
                        <Icon name="circle-close" size="24"/>
                        Clear all
                    </button>
                    : ''
                }
            </div>
        </div>
    );
};

export default Preview;
